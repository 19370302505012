import { graphql, PageProps } from "gatsby";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import MyLink from "../components/MyLink";
import { Difference } from "../components/Difference";
import arrowImg from "../images/arrow.svg";
import arrow01 from "../images/arrow01.svg";
import checkIcon from "../images/check_icon.svg";
import reason01 from "../images/reason01.png";
import reason02 from "../images/reason02.png";
import reason03 from "../images/reason03.png";
import linkicon from "../images/outerlink.svg";
import { Card02 } from "../components/Card02";
import { Button01 } from "../components/Button01";
import { Cta03 } from "../components/Cta03";
import bgimgPc from "../images/h1/h1features.png";
import bgimgSp from "../images/h1/h1features_sp.png";
import TextLoop from "../components/TextLoop";

const FeaturesPage: React.VFC<PageProps<GatsbyTypes.FeaturesIndexQuery>> = ({
  data,
}) => {
  const differences = data.wpProduct?.productAcf?.differences;
  const cases = data.allWpCase.edges;
  const worries = [
    "段差スロープの入出庫時のガタツキ音が気になる",
    "現在使用しているスロープがすぐボロボロになってしまった",
    "段差スロープが雨で流される",
    "設置したばかりなのに割れてしまった",
    "いつも安いものを買って、すぐに壊れてしまう",
    "入出庫時の音がうるさくて気になる",
  ];
  return (
    <Layout title="私たちの特長" description="ミスギの私たちの特長ページです。">
      <Heading01
        label="FEATURES"
        smallLabel="私たちの特長"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "私たちの特長" }]} />
      <div className="l-container-sm mt-3 mb-12 pc:mt-[50px] pc:mb-20">
        <div className="flex flex-col justify-between pc:flex-row pc:items-center">
          <div className="font-bold">
            <span className="text-[16px] tracking-[.024em] text-primary">
              Question
            </span>
            <h2 className="mt-3 text-xl pc:text-3xl">
              スロープのこんなお悩み
              <br />
              ありませんか？
            </h2>
          </div>
          <ul className="mt-6 space-y-6 pc:mt-0 pc:w-1/2">
            {worries.map((worry, index) => (
              <li key={index} className="flex">
                <span className="flex-shrink-0">
                  <img src={checkIcon} alt="" />
                </span>
                <p className="text- pl-4 text-sm font-bold pc:text-xl pc:font-medium">
                  {worry}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="">
          <div className="flex flex-col pc:flex-row"></div>
          <div className="mt-8 pc:mt-16">
            <img src={arrowImg} alt="" />
          </div>
          <p className="mt-3 text-center text-base font-bold pc:mt-8 pc:text-2xl">
            そんなお悩み
            <br className="pc:hidden" />
            <strong className="pl-7 text-2xl font-bold text-primary pc:pl-0 pc:text-[56px] pc:tracking-[.056em]">
              ミスギの製品
            </strong>
            なら解決できます！
          </p>
        </div>
      </div>

      {/* ミスギ製品が選ばれる理由 */}
      <div className="relative bg-bggrey pt-[80px] pb-12 pc:pt-[160px] pc:pb-20">
        <div className="absolute top-0 -translate-y-1/2">
          <TextLoop
            className="text-[112px] text-white pc:text-[200px]"
            text="MISUGI"
            design={2}
          />
        </div>
        <div className="l-container-sm">
          <h3 className="text-center text-xl font-bold tracking-[.06em] pc:text-[40px]">
            ミスギ製品が
            <span className="text-primary">
              <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                選
              </strong>
              <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                ば
              </strong>
              <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                れ
              </strong>
              <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                る
              </strong>
              理由
            </span>
          </h3>
          <ul className="mt-20 grid gap-y-16 pc:mt-28 pc:grid-cols-3 pc:gap-y-0 pc:gap-x-10">
            <li className="rounded-2xl bg-white p-10 shadow-[5px_5px_30px_rgba(43,130,63,0.1)]">
              <div className="-mt-24 text-center text-[72px] font-light">
                0<span className="text-primary">1</span>
              </div>
              <div className="mt-4">
                <img className="mx-auto w-[130px]" src={reason01} alt="" />
              </div>
              <p className="mt-4 text-center text-base font-medium pc:mt-6 pc:text-xl">
                “段差を解消する” <br />
                だけじゃない。
                <br />
                44年こだわった品質の
                <br />
                高さを実現
              </p>
            </li>
            <li className="rounded-2xl bg-white p-10 shadow-[5px_5px_30px_rgba(43,130,63,0.1)]">
              <div className="-mt-24 text-center text-[72px] font-light">
                0<span className="text-primary">2</span>
              </div>
              <div className="mt-4">
                <img className="mx-auto w-[117px]" src={reason02} alt="" />
              </div>
              <p className="mt-4 text-center text-base font-medium pc:mt-6 pc:text-xl">
                誰にでも使いやすい
                <br />
                最適な福祉製品
                <br />
                ”かわさき基準” に認証
                <br />
                <MyLink
                  myClassName="text-[14px] -ml-4 tracking-[0] leading-[40px] relative font-normal text-primary"
                  to="https://www.city.kawasaki.jp/jigyou/category/79-31-3-0-0-0-0-0-0-0.html"
                >
                  かわさき基準認証制度とは
                  <img
                    className="absolute -right-5 top-0"
                    src={linkicon}
                    alt=""
                  />
                </MyLink>
              </p>
            </li>
            <li className="rounded-2xl bg-white p-10 shadow-[5px_5px_30px_rgba(43,130,63,0.1)]">
              <div className="-mt-24 text-center text-[72px] font-light">
                0<span className="text-primary">3</span>
              </div>
              <div className="mt-4">
                <img className="mx-auto w-[151px]" src={reason03} alt="" />
              </div>
              <p className="mt-4 text-center text-base font-medium pc:mt-6 pc:text-xl">
                全ての製品が日本製。
                <br />
                安心・安全な製品を
                <br />
                提供します
              </p>
            </li>
          </ul>
        </div>
      </div>

      {/* 他社製品との違い */}
      <div className="l-container-sm py-16 pc:py-[120px]">
        <h3 className="text-center text-xl font-bold tracking-[.06em] pc:text-[40px]">
          他社製品とは、
          <span className="text-primary">
            <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
              こ
            </strong>
            <strong className="relative font-bold before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
              こ
            </strong>
            が違う
          </span>
        </h3>
        <ul className="mt-10">
          <div className="mt-4">
            {<Difference number={1} {...differences?.difference01} />}
          </div>
          <div className="mt-6 border-t border-solid border-[#E0E0E0]">
            {<Difference number={2} {...differences?.difference02} />}
          </div>
        </ul>
      </div>
      {/* 他社製品との違いここまで */}

      {/* 多くの施工実績 */}
      <div className="relative bg-bggrey pt-10 pb-16 pc:py-[72px]">
        <div className="l-container-sm">
          <h2 className="text-center text-xl font-bold pc:text-left pc:text-3xl">
            多くの施工実績
          </h2>
          <div className="mt-10 pc:mt-12">
            <ul className="grid grid-cols-1 gap-y-8 tb:grid-cols-2 tb:gap-x-5 pc:grid-cols-3">
              {cases.map(
                (nodes, index) =>
                  nodes.node.title && (
                    <Card02
                      key={index}
                      heading={nodes.node.title}
                      link={`/case/${nodes.node.slug}`}
                      description={nodes.node.caseAcf?.catch}
                      img={nodes.node.featuredImage?.node}
                    />
                  )
              )}
            </ul>
          </div>
          <div className="mt-10 text-center">
            <Button01
              label="施工事例をもっと見る"
              link="/case"
              isWhite={true}
              isPageTransition={true}
            />
          </div>
        </div>
        {/* 文字ループアニメーション */}
        <div className="absolute top-0 left-0 hidden h-full w-32 items-start pc:flex">
          <TextLoop
            text="CASE CASE CASE"
            className="w-[735px] flex-shrink-0 origin-top-left translate-x-[120px] rotate-90 tracking-[0.048em] text-white pc:text-[104px]"
            design={2}
          />
        </div>
        <div className="absolute top-0 right-0 hidden h-full w-32 items-end pc:flex">
          <TextLoop
            text="CASE CASE CASE"
            className="w-[735px] flex-shrink-0 origin-bottom-left translate-x-[120px] -rotate-90 tracking-[0.048em] text-white pc:text-[104px]"
            design={2}
          />
        </div>
      </div>

      {/* cta */}
      <div className="l-container-sm pt-12 pb-6 pc:pt-28 pc:pb-12">
        <Cta03 />
        <div className="mt-6 pc:mt-8">
          <img className="mx-auto" src={arrow01} alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default FeaturesPage;

export const query = graphql`
  query FeaturesIndex {
    allWpCase(limit: 3, sort: { fields: date, order: ASC }) {
      edges {
        node {
          title
          slug
          caseAcf {
            catch
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    wpProduct(id: { eq: "cG9zdDoxMDg=" }) {
      title
      productAcf {
        differences {
          difference01 {
            catchText: catch
            comparison {
              pc {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              sp {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            label
            myproduct {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            normal {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          difference02 {
            catchText: catch
            comparison {
              pc {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              sp {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            label
            myproduct {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            normal {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
