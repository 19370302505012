import React from "react";
import ctaImg from "../../images/logo2.png";

/**
 * cta3つ目
 * 特徴ページで使用
 */
export const Presenter: React.VFC = () => {
  const items = [
    "実物を見てみたい",
    "とりあえず話が聞きたい",
    "本当に質がいいの？",
    "商品の魅力を直接聞きたい",
  ];
  return (
    <div className="rounded-[32px] bg-white px-6 pt-14 pb-12 drop-shadow-[0_5px_5px_rgba(43,130,63,0.3)] pc:px-16 pc:pt-14 pc:pb-16">
      <div className="">
        <img
          className="mx-auto w-[276px] pc:w-[508px]"
          src={ctaImg}
          alt="ロゴ"
        />
      </div>
      <p className="mt-14 text-center text-xl font-bold pc:mt-20 pc:text-3xl">
        ぜひ、ミスギの製品を
        <br className="pc:hidden" />
        お試しください！
      </p>
      <ul className="mx-auto mt-6 flex w-[280px] flex-col items-center space-y-2 pc:w-full pc:flex-row pc:space-x-4">
        {items.map((item, index) => (
          <li
            key={index}
            className={`inline-block rounded-2xl bg-secondary px-5 py-2 text-xs font-bold 
            text-primary pc:whitespace-nowrap pc:text-base ${
              index % 2 ? `ml-auto` : `mr-auto`
            }`}
          >
            {item}
          </li>
        ))}
      </ul>
      <p className="mt-6 text-center text-base">
        どんなことでも、
        <br className="pc:hidden" />
        お気軽にご相談ください！
      </p>
    </div>
  );
};
