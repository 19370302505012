import React from "react";

import { Props } from "../../types/Difference";

import svg01 from "../../images/01.svg";
import svg02 from "../../images/02.svg";
import { WpImage } from "../WpImage";

/**
 * 他社製品との違いの個別ブロックです
 */
export const Presenter: React.VFC<Props> = ({
  number,
  label,
  catchText,
  normal,
  comparison,
  myproduct,
}) => {
  return (
    <li className="pb-14 pt-10 pc:pt-[64px] pc:pb-[120px]">
      <span className="mx-auto flex items-end justify-center text-center">
        <img className="" src={number === 1 ? svg01 : svg02} alt="" />
        <p className="text-xs font-bold text-primary pc:text-base">{label}</p>
      </span>
      {catchText && (
        <h4
          className="mt-4 text-center text-base font-bold pc:mt-6 pc:text-3xl"
          dangerouslySetInnerHTML={{
            __html: catchText,
          }}
        ></h4>
      )}
      <div className="gap mt-[72px] flex flex-col justify-center gap-12 pc:flex-row pc:gap-20">
        <div className="flex w-full flex-col items-center pc:w-[460px]">
          <p className="mb-3 text-center text-base text-grey pc:mb-6">
            {normal?.title}
          </p>
          <WpImage image={normal?.img} className={`rounded-lg`} />
        </div>
        <div
          className="relative flex w-full flex-col items-center before:absolute before:top-[-32px] before:left-1/2 before:z-10 before:h-4 before:w-4 before:-translate-x-1/2
            before:rotate-[135deg] before:border-t before:border-r before:border-solid before:border-primary before:content-[''] pc:w-[460px]
             pc:before:-left-[50px] pc:before:top-[60%] pc:before:translate-x-0 pc:before:rotate-45"
        >
          <p className="mb-3 text-center text-base font-bold text-primary pc:mb-6">
            {myproduct?.title}
          </p>
          <WpImage image={myproduct?.img} className={`relative rounded-lg`} />
        </div>
      </div>
      <div className="mt-4 hidden text-center pc:block">
        <WpImage image={comparison?.pc} />
      </div>
      <div className="mt-4 text-center pc:hidden">
        <WpImage image={comparison?.sp} />
      </div>
    </li>
  );
};
